<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main content-auto">
        <ayl-table :table="table" @selection-change="selectionChange">
          <div slot="ctrl-button">
            <div class="export-btn ml10px" @click="clickExport">导 出</div>
          </div>
        </ayl-table>
      </div>
    </div>

    <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="900px" center>
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
        <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="exportExcel" class="mr24px" :loading="btnLoading">确 定</el-button>
          <el-button @click="dialogExport=false">取 消</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
  import mixins from '@utils/mixins'

  export default {
    mixins: [mixins.$exportCheckAll],
    data () {
      const vm = this
      return {
        nav: [
          {name: '会计对账'},
          {name: '外请车主统计'},
        ],
        table: {
          api: vm.$api.ListDriverAccountCheckingInfo,
          query: {
            orderStatus: '已核算',
            startTime: null,
            endTime: null,
            driverType: '外请车主',
            queryContent: null,
          },
          searchData: [{
            type: 'dateSection',
            title: '查询日期',
            isOptions: true,
            model: [],
            modelName: ['startTime', 'endTime']
          }, {
            type: 'input',
            model: 'queryContent',
            placeholder: '驾驶员姓名'
          }],
          columns: [{
            type: 'selection',
            fixed: 'left',
          }, {
            title: '驾驶员',
            key: 'driverName'
          }, {
            title: '手机号',
            key: 'contactPhone',
          }, {
            title: '成本运费',
            key: 'costCarrierFee',
          }, {
            title: '待垫费用',
            key: 'advanceFee',
          }, {
            title: '信息费',
            key: 'messageFee',
          }, {
            title: '订单数',
            key: 'orderCount',
          }, {
            title: '操作',
            width: '100px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.goExamine.bind(this, ctx.row)
                  }
                }, '承运明细')
              ])
            }
          }]
        },
        checkDriver: [],
        dialogExport: false,
        checkIndex: [],
        checkList: [],
        btnLoading: false,
        queryIdList: []
      }
    },
    methods: {
      clickExport () {
        if (this.queryIdList.length === 0) return this.$message.warning('请选择要导出的司机')
        this.dialogExport = true
        this.$api.GetExcelExportKeyAndColumn({
          excelKey: 'SteelOutsideDriverStatistics'
        }).then((res) => {
          this.checkList = res.chsList
          this.checkIndex = this.checkList.map(e => e.index)
        })
      },
      selectionChange (e) {
        this.queryIdList = e
      },
      async exportExcel () {
        this.btnLoading = true
        try {
          const arr = this.queryIdList.map(e => e.driverId)
          const url = await this.$api.Excel({
            excelKey: 'SteelOutsideDriverStatistics',
            indexList: this.checkIndex,
            params: {
              orderStatus: '',
              driverType: '',
              startTime: this.table.query.startTime || null,
              endTime: this.table.query.endTime || null,
              queryContent: this.table.query.queryContent || '',
              queryIdList: arr
            }
          })
          window.open(url)
          this.dialogExport = false
          await this.$search(this.table)
        } catch (e) {
          console.log(e)
        }
        this.btnLoading = false
      },
      goExamine (e) {
        this.$router.push({
          path: '/accounting/external-detailed',
          query: {
            driverId: e.driverId,
            driverName: e.driverName,
            startTime: this.table.query.startTime,
            endTime: this.table.query.endTime,
          }
        })
      },
    },
    beforeRouteEnter (to, from, next) {
      if (from.path === '/accounting/external-detailed') {
        to.meta.isBack = true
      } else {
        to.meta.isBack = false
      }
      next()
    },
    async activated () {
      if (!this.$route.meta.isBack) {
        this.$set(this.table.query, 'startTime', null),
        this.$set(this.table.query, 'endTime', null),
        this.table.searchData[0].model = [],
        this.table.searchData[0].modelName = ['startTime', 'endTime'],
        this.table.query.queryContent = null
        // await this.$search(this.table)
      } else {
        // await this.$search(this.table)
        this.$route.meta.isBack = false // 重置详情页标识isBack
      }
    },
    async mounted () {
      // await this.$search(this.table)
    }
  }
</script>

<style lang='sass' scoped>
  /deep/ .el-checkbox
    min-width: 92px
</style>
